import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/propertiUnit'

export default {
  get (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  show (idPropertiUnit) {
    return httpClient.get(`${endpoint}/${idPropertiUnit}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getAll (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/all${queryParams}`)
  },

  getAllByProyek (idProyek) {
    return httpClient.get(`${endpoint}/allByProyek/${idProyek}`)
  },

  getDokumenCustomer (idPropertiUnit) {
    return httpClient.get(`${endpoint}/${idPropertiUnit}/dokumenCustomer`)
  },

  getDokumenUnit (idPropertiUnit) {
    return httpClient.get(`${endpoint}/${idPropertiUnit}/dokumenUnit`)
  },

  getPembayaran (idPropertiUnit) {
    return httpClient.get(`${endpoint}/${idPropertiUnit}/pembayaran`)
  },

  updateCoordinates (params) {
    return httpClient.patch(`${endpoint}/coordinates`, params)
  },

  removeCoordinates (idPropertiUnit) {
    return httpClient.delete(`${endpoint}/coordinates/${idPropertiUnit}`)
  },

  getHarga (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/harga${queryParams}`)
  }
}
